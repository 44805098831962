import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { setClientNavId } from '../store/actions/clientActions'
import history from '../history'
import { getAllAboutUs } from '../store/actions/aboutUsActions'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '30px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const MediaList = () => (
    <>
        <a href="https://www.facebook.com/HSpaceSolutions" target="_black">
            <FacebookIcon style={{ fontSize: 30 }} />
        </a>
        <a href="https://twitter.com/Hspaceonline" target="_black">
            <TwitterIcon style={{ fontSize: 30 }} />
        </a>
        <a href="https://www.instagram.com/homespace_online" target="_black">
            <InstagramIcon style={{ fontSize: 30 }} />
        </a>
        <a
            href="https://www.youtube.com/channel/UCgIIU4yj_tRmDoOfeEj_kBA"
            target="_black"
        >
            <YouTubeIcon style={{ fontSize: 30 }} />
        </a>
    </>
)

const footerList = [
    [
        { name: 'All Products', link: '/productspage' },
        { name: 'Cabinets', link: '/cabinets' },
        { name: 'Home Decor', link: '/homedecor' },
        { name: 'Accessories', link: '/accessories' },
        { name: 'Design Center', link: '/designcenter' },
    ],
    [
        { name: 'Support', link: '/support' },
        { name: 'Warranty', link: '/warranty' },
        { name: 'Cabinet Care', link: '/cabinetcare' },
        { name: 'Self Install Process', link: '/selfassemble' },

        { name: 'FAQ', link: '/faq' },
    ],
    [
        { name: 'About Us', link: '/aboutus' },
        { name: 'Policy', link: '/policy' },
        { name: 'Price Match', link: '/bestprice' },
        { name: 'Order Sample', link: '/sample/cabinet-sample' },
        { name: 'Track My Order', link: '/trackorder' },
    ],
]

const Footer = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const aboutUsState = useSelector((state) => state.aboutUs)
    const { headOffices } = aboutUsState

    useEffect(() => {
        if (!headOffices) {
            dispatch(getAllAboutUs())
        }
    }, [dispatch, headOffices])

    const date = new Date()
    const year = date.getFullYear()
    return (
        <Container disableGutters={true}>
            <div className="footer_box">
                <div className="footer_full">
                    <Divider className={classes.divider} />
                </div>
                <div className="footer_container">
                    <div className="footer_col1">
                        <Link to="/">
                            <img
                                src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/HomeSpace_Logo-09_igr9cr.png"
                                alt="logo"
                                width="100px"
                                className="footer_logo"
                                style={{ objectFit: 'contain' }}
                            />
                        </Link>
                        {headOffices?.map((headOffice) => (
                            <div
                                style={{ marginBottom: '5px', width: '150px' }}
                                key={headOffice?.id}
                            >
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        headOffice?.address.includes(
                                            'Mississauga'
                                        )
                                            ? 'hspace kitchen & bath '
                                            : '' + headOffice?.address
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {headOffice?.address}
                                </a>
                            </div>
                        ))}
                        <div style={{ margin: '5px' }}></div>
                        <div>T: 1-833-770-7077</div>
                        {/* <div>E: info@hs-online.com</div> */}
                    </div>
                    <div className="footer_item_mobile_page">
                        <div className="footer_copyright_media_mobile">
                            <MediaList />
                        </div>
                        <div style={{ width: '80%' }}>
                            <Divider className={classes.divider} />
                        </div>
                        <div className="footer_font_bold">
                            {clientInfo ? (
                                <span
                                    onClick={() => {
                                        dispatch(setClientNavId(3))
                                        history.push('/clientcart')
                                    }}
                                >
                                    View Cart
                                </span>
                            ) : (
                                <span
                                    onClick={() => {
                                        history.push('/cart')
                                    }}
                                >
                                    View Cart
                                </span>
                            )}
                        </div>
                        <div className="footer_font_bold">
                            <Link to="/login">Login</Link> |{' '}
                            <Link to="/signin">Sign-in</Link>
                        </div>
                        <div className="footer_item_list"></div>
                        <div className="footer_item_list_1">
                            <a
                                href="https://designer.hs-online.com"
                                target="_black"
                            >
                                Become a designer
                            </a>
                        </div>
                        <div
                            className="footer_item_list_1"
                            style={{ marginTop: '20px' }}
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => history.push('/trackorder')}
                            >
                                Designer Login
                            </span>
                        </div>
                    </div>
                    {footerList.map((list, index) => (
                        <div className="footer_item" key={index}>
                            {list.map((item) => (
                                <div
                                    className="footer_item_list"
                                    key={item.name}
                                >
                                    <Link to={item.link}>{item.name}</Link>
                                </div>
                            ))}
                        </div>
                    ))}

                    <div className="footer_item_full_page">
                        <div className="footer_item_list ">
                            {clientInfo ? (
                                <span
                                    className="footer_font_yellow"
                                    onClick={() => {
                                        dispatch(setClientNavId(3))
                                        history.push('/clientcart')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    View Cart
                                </span>
                            ) : (
                                <span
                                    className="footer_font_yellow"
                                    onClick={() => {
                                        history.push('/cart')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    View Cart
                                </span>
                            )}
                        </div>
                        <div className="footer_item_list  footer_font_yellow">
                            <Link to="/login">
                                <span className="footer_font_yellow">
                                    Login
                                </span>
                            </Link>{' '}
                            |{' '}
                            <Link to="/signin">
                                <span className="footer_font_yellow">
                                    Sign-in
                                </span>
                            </Link>
                        </div>
                        <div
                            className="footer_item_list"
                            style={{ color: '#fff' }}
                        >
                            .
                        </div>
                        <div className="footer_item_list footer_item_list_1">
                            <a
                                href="https://designer.hs-online.com"
                                target="_black"
                            >
                                Become a designer
                            </a>
                        </div>
                        <div className="footer_item_list footer_item_list_1">
                            <a
                                href="https://designer.hs-online.com/login"
                                target="_black"
                            >
                                Designer Login
                            </a>
                        </div>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className="footer_copyright">
                    <div className="footer_copyright_font">
                        &copy; {year} All Rights reserved
                    </div>
                    <div className="footer_copyright_media_full">
                        <MediaList />
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Footer
