import { Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import history from '../history'

const Bogo = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="bogo_container">
                <div className="bogo_bgpic">
                    <div className="bogo_text_box">
                        <div className="bogo_text_area">
                            <h3>Hspace Buy One Get One Promotion</h3>
                            <p>
                                Buy any in-store and ready-to-ship lower
                                cabinet, get the matching number of upper
                                cabinet FREE! Plus expert design, cabinet
                                assemble and quick delivery. PROMOTION VALID: 9
                                OCT 22 - 24 OCT 22
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Container disableGutters={true} style={{ padding: '30px 0' }}>
                <div className="bogo_detail_box">
                    <div className="bogo_detail_left">
                        <div>
                            <div className="bogo_detail_header">Cabinets</div>
                            <div className="bogo_detail_sub_title">
                                BUY ONE GET ONE FREE ON ALL IN-STORE CUSTOM
                                CABINETS!
                            </div>
                            <div className="bogo_detail_text">
                                "buy lower cabinet, get the matching number of
                                upper cabinet FREE, Excludes pantry cabinet oven
                                pantry cabinet and trims."
                            </div>
                        </div>
                        <button
                            className="bogo_detail_button"
                            onClick={() => history.push('/cabinets')}
                        >
                            Shop Now
                        </button>
                    </div>
                    <div className="bogo_detail_right">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1660158109/New%20Website%20lookout%20%28for%20yanni%29/Cabient/pexels-curtis-adams-5071143_szqkcj.webp"
                            className="bogo_pic"
                            alt="pic"
                        />
                    </div>
                </div>
                <div className="bogo_detail_box">
                    <div className="bogo_detail_left">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1642045244/Request_Design/Screen_Shot_2022-01-12_at_10.40.07_PM_jnngdz.webp"
                            className="bogo_pic"
                            alt="pic"
                        />
                    </div>
                    <div className="bogo_detail_right">
                        <div>
                            <div className="bogo_detail_header">
                                Design Center
                            </div>
                            <div className="bogo_detail_sub_title">
                                ONE-ON-ONE KITCHEN DESIGN
                            </div>
                        </div>
                        <div className="bogo_detail_button_box">
                            <button
                                className="bogo_detail_button"
                                onClick={() => history.push('/designcenter')}
                            >
                                Go To Design Center
                            </button>
                            <button
                                className="bogo_detail_button"
                                onClick={() => history.push('/bookdesign')}
                            >
                                Book A Designer For Free
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bogo_detail_box">
                    <div className="bogo_detail_left">
                        <div>
                            <div className="bogo_detail_header">
                                Handles And Knobs
                            </div>
                            <div className="bogo_detail_sub_title">
                                KITCHEN HANDLES FOR EVERY STYLES AND BUDGET
                            </div>
                        </div>
                        <button
                            className="bogo_detail_button"
                            onClick={() =>
                                history.push('/products/accessories/handles')
                            }
                        >
                            Shop Now
                        </button>
                    </div>
                    <div className="bogo_detail_right">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1663898611/New%20Website%20lookout%20%28for%20yanni%29/Accessories/Aurora%20Knobs_f169ac.webp"
                            className="bogo_pic"
                            alt="pic"
                        />
                    </div>
                </div>
                <div className="bogo_detail_box">
                    <div className="bogo_detail_left">
                        <img
                            src="https://res.cloudinary.com/homespace/image/upload/v1663727093/samples/028f0a8bb13938d0bfc304d9f3041a03_dz7pcv.webp"
                            className="bogo_pic_cover"
                            alt="pic"
                        />
                    </div>
                    <div className="bogo_detail_right">
                        <div>
                            <div className="bogo_detail_header">
                                Cabinet Sample
                            </div>
                            <div className="bogo_detail_sub_title">
                                FREE SHIPPING FOR ALL SAMPLE DOORS
                            </div>
                        </div>
                        <button
                            className="bogo_detail_button"
                            onClick={() =>
                                history.push('/sample/cabinet-sample')
                            }
                        >
                            Order Now
                        </button>
                    </div>
                </div>
                <div className="bogo_bottom_box">
                    <div className="bogo_bottom_bgpic">
                        <div className="bogo_bottom_text_box">
                            <div className="bogo_bottom_text_area">
                                <h3>Thanksgiving Buy One Get One Free Sale</h3>
                                <p>
                                    To get the BOGO promo code, customer need to
                                    pay 50% deposit of their order list.
                                    Customer is required to pay full prior in 3
                                    month (before January 24th 11:59pm on 2023)
                                    to keep this BOGO discount for the finial
                                    deal.
                                </p>
                                <div className="bogo_bottom_text_line">
                                    GET YOUR EARLY BIRD BOGO CODE!
                                </div>
                                <div className="bogo_bottom_text_line_2">
                                    FROM NOW TO 2022.09.30
                                </div>
                            </div>
                            <div className="bogo_bottom_button_area">
                                <a
                                    href="https://calendly.com/homespace-online"
                                    target="_black"
                                >
                                    <button className="bogo_bottom_button">
                                        Free Consultation For More Details
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Bogo
